.select-image {
  margin-left: 30px;
}

.select-group {
  display: flex;
  margin: 20px 0px;
}

.select-label {
  width: 250px;
  padding-top: 5px;
}

.select-option {
  width: 700px;
}

.position-list-table {
  width: 950px;
  max-height: 500px;
}

.get-image-button {
  margin-top: 20px;
  margin-left: 3px;
}

.select-label,
.select-option,
.get-image-button,
.ant-table-thead,
.position-list-table td,
.get-image-button {
  font-size: 18px;
}
