div.inspection-results-acquisition-container {
  background-color: #F0F2F5;
  min-height: 100%;
}

.inspection-results-acquisition-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
}

.radio-button{
  font-size: 18px;
}

.image-table-wrapper{
  height: calc(100vh - 56px);
}