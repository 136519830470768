div.correction-image-container {
  background-color: #F0F2F5;
  min-height: 100%;
}

.correction-image-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
}

.image-table-wrapper{
  height: calc(100vh - 56px);
}

.image-info-header {
  margin-left: 30px;
}

.upload-button {
  float: right;
}

div.ant-modal,
.confirm-modal {
  margin: 0 auto;
}