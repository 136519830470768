.ant-menu-item .footer {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 28px;
}

.ant-menu-inline .ant-menu-item {
  font-family: sans-serif, "メイリオ";
  font-size: 18px;
}

#oss-link-div {
  position:absolute;
  bottom:10px;
  left:380px;
  -webkit-text-stroke: 1px #05F;
}