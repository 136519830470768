.overlap-images{
  overflow: auto;
  height: 70%;
  width: auto;
  border: 0px solid black;
  margin:30px
}

.overlap-contents{
  display: flex;
  flex-wrap: wrap;
}

.image-no{
  text-align: center;
  padding-bottom: 5px;
  font-size: 18px;
}

.img-button{
  border: 0px;
  padding: 0px;
}

.image{
  width: 20vw;
  padding: 5px;
  border: 1px solid #D9D9D9;
}

.can-check-image{
  width: 20vw;
  padding: 5px;
  border: 1px solid #D9D9D9;
  cursor:pointer
}

.checkbox-lapper{
  margin-top: 5px;
  text-align: center;
}
.contents{
  display: flex;
  flex-direction:column;
  margin: 5px;
}