.video-upload-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
}

.video-upload-container {
  margin: 50px;
}

.video-upload-container input {
  width: 350px;
  margin-left: -130px;
}

.ant-form-item-label {
  text-align: left;
}

.ant-form label {
  font-size: 18px;
}

.ant-form-explain {
  margin-left: -130px;
  font-size: 18px;
}

.ant-btn {
  margin-left: -120px;
  font-size: 18px;
}

.ant-btn-icon-only {
  margin-left: 0;
}

.video-upload-container .example-container {
  margin-left: 20px;
  font-size: 18px;
}
